export const languages = {
  en: {
    notMember: "Not a Member?",
    createAccount: "Create Account",
    backTo3ady: "BACK TO",
    emailAddress: "Email Address",
    yourPassword: "Your Password",
    forgotPassword: "Forgot your password?",
    createNewPassword: "New Password",
    pleaseWait: "Please wait...",
    accessMyAccount: "Access My Account",
    contactSupport:
      "If you have issues accessing your account, contact us via WhatsApp:",
    authError: "Error authenticating",
  },
  fr: {
    notMember: "Non membre?",
    createAccount: "Créer un compte",
    backTo3ady: "RETOUR À",
    emailAddress: "Adresse e-mail",
    yourPassword: "Votre mot de passe",
    forgotPassword: "Mot de passe oublié?",
    createNewPassword: "Nouvelle mdp",
    pleaseWait: "Veuillez patienter...",
    accessMyAccount: "Accéder à mon compte",
    contactSupport:
      "En cas de problème d'accès, contactez-nous via WhatsApp:",
    authError: "Erreur lors de l'authentification",
  },
  pt: {
    notMember: "Não é Membro?",
    createAccount: "Criar conta",
    backTo3ady: "DE VOLTA À",
    emailAddress: "Endereço de E-mail",
    yourPassword: "Sua senha",
    forgotPassword: "Esqueceu sua senha?",
    createNewPassword: "Nova senha",
    pleaseWait: "Aguarde...",
    accessMyAccount: "Acessar minha conta",
    contactSupport:
      "Caso tenha problema com seu acesso, fale conosco através do WhatsApp:",
    authError: "Erro ao se autenticar",
  },
};
