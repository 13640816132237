import { React, useState, useEffect, useRef } from "react";
import { useAuth } from "../../../context/Auth";
import routes from '../../../routes/Path.js';
import {
  updateLastLogin,
  getClientLocation,
  getUserIP,
} from "../../../services/Calls";
import { useToast } from "../../../context/Toast";
import { useNavigate } from "react-router-dom";

import "../styles.css";
import { languages } from "../../../language/login.js";

import { eyeSlash, eye } from "../../../imports/icons";
import { Navigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

function Login() {
  const [loading, setLoading] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(() => {
    return localStorage.getItem("activeLanguage") || "en";
  });

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();
  const { signInWithEmailAndPassword, signed } = useAuth();

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const [email, setEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isFormValid = emailInput !== "" && passwordInput;

  const { showCustomToast } = useToast();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if (passwordInputRef.current) {
      passwordInputRef.current.type = showPassword ? "password" : "text";
    }
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };

  async function handleEmailAndPasswordSignIn(e) {
    e.preventDefault();
    setLoading(true);

    const LastJLogin = Timestamp.now();
    const LastLocation = await getClientLocation();
    const LastIp = await getUserIP();

    try {
      signInWithEmailAndPassword(emailInput, passwordInput);
      updateLastLogin(emailInput, LastJLogin, LastLocation, LastIp);
      setLoading(false);
    } catch (err) {
      showCustomToast(languages[activeLanguage].authError, "error");
      console.error("Error authenticating with email and password", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (email) {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
    }
  }, [email]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("activeLanguage");
    if (savedLanguage) {
      setActiveLanguage(savedLanguage);
    }

    setEmail(true);
  }, []);

  if (!signed) {
    return (
      <section className="ct-access">
        <div className="access">
          <form>
            <h1>
              {languages[activeLanguage].backTo3ady}{" "}
              <span className="brand" onClick={() => navigate(routes.home)}>
                <strong>3ÄDY</strong>
              </span>
            </h1>
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                ref={emailInputRef}
                value={emailInput}
                onChange={handleEmailChange}
              />
              <label for="floatingInput">
                {languages[activeLanguage].emailAddress}
              </label>
            </div>
            <div class="form-floating">
              <input
                type="password"
                class="form-control password-login"
                id="floatingPassword"
                ref={passwordInputRef}
                value={passwordInput}
                onChange={handlePasswordChange}
              />
              <label for="floatingPassword">
                {languages[activeLanguage].yourPassword}
              </label>
              <span className="eye" onClick={togglePasswordVisibility}>
                <img
                  loading="lazy"
                  alt="eye"
                  src={showPassword ? eyeSlash : eye}
                />
              </span>
            </div>
            <p className="label-links label-password">
              {languages[activeLanguage].forgotPassword}{" "}
              <span className="links" onClick={() => navigate(routes.password)}>
                {languages[activeLanguage].createNewPassword}
              </span>
            </p>
            <div>
              {loading ? (
                <button
                  type="submit"
                  className="btn btn-outline-dark disable"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  {languages[activeLanguage].pleaseWait}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-dark"
                  onClick={handleEmailAndPasswordSignIn}
                  disabled={!isFormValid}
                >
                  {languages[activeLanguage].accessMyAccount}
                </button>
              )}
            </div>
            <p className="label-links support">
              {languages[activeLanguage].notMember}{" "}
              <span className="links" onClick={() => navigate(routes.register)}>
                {languages[activeLanguage].createAccount}
              </span>
              {". "}
              {languages[activeLanguage].contactSupport}{" "}
              <span
                onClick={() =>
                  window.open("https://wa.me/5548991586445", "_blank")
                }
                className="links"
              >
                +55 48 99158-6445
              </span>
            </p>
          </form>
        </div>
      </section>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
}

export default Login;
