import one from "../assets/profile/1.svg";
import two from "../assets/profile/2.svg";
import three from "../assets/profile/3.svg";
import four from "../assets/profile/4.svg";
import five from "../assets/profile/5.svg";
import six from "../assets/profile/6.svg";
import seven from "../assets/profile/7.svg";
import eight from "../assets/profile/8.svg";
import nine from "../assets/profile/9.svg";
import ten from "../assets/profile/10.svg";
import eleven from "../assets/profile/11.svg";
import twelve from "../assets/profile/12.svg";
import thirteen from "../assets/profile/13.svg";
import fourteen from "../assets/profile/14.svg";
import fifteen from "../assets/profile/15.svg";
import sixteen from "../assets/profile/16.svg";
import seventeen from "../assets/profile/17.svg";
import eighteen from "../assets/profile/18.svg";
import nineteen from "../assets/profile/19.svg";
import twenty from "../assets/profile/20.svg";
import twentyOne from "../assets/profile/21.svg";
import twentyTwo from "../assets/profile/22.svg";
import twentyThree from "../assets/profile/23.svg";
import twentyFour from "../assets/profile/24.svg";
import twentyFive from "../assets/profile/25.svg";
import twentySix from "../assets/profile/26.svg";
import twentySeven from "../assets/profile/27.svg";
import twentyEight from "../assets/profile/28.svg";
import twentyNine from "../assets/profile/29.svg";
import thirty from "../assets/profile/30.svg";
import thirtyOne from "../assets/profile/31.svg";
import thirtyTwo from "../assets/profile/32.svg";
import thirtyThree from "../assets/profile/33.svg";
import thirtyFour from "../assets/profile/34.svg";
import thirtyFive from "../assets/profile/35.svg";
import thirtySix from "../assets/profile/36.svg";


export const profileIcons = {
  1: one,
  2: two,
  3: three,
  4: four,
  5: five,
  6: six,
  7: seven,
  8: eight,
  9: nine,
  10: ten,
  11: eleven,
  12: twelve,
  13: thirteen,
  14: fourteen,
  15: fifteen,
  16: sixteen,
  17: seventeen,
  18: eighteen,
  19: nineteen,
  20: twenty,
  21: twentyOne,
  22: twentyTwo,
  23: twentyThree,
  24: twentyFour,
  25: twentyFive,
  26: twentySix,
  27: twentySeven,
  28: twentyEight,
  29: twentyNine,
  30: thirty,
  31: thirtyOne,
  32: thirtyTwo,
  33: thirtyThree,
  34: thirtyFour,
  35: thirtyFive,
  36: thirtySix,
};
