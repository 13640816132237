import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { LanguageContext } from "../../context/Idiom.js";
import routes from '../../routes/Path.js';

import {
  getNotifications,
  markNotificationAsRead,
} from "../../services/controller/Notify.js";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/pt";
import {
  triangleFill,
  out,
  bell,
  menu,
  pancake,
  bscscan,
  coinmarketcap,
  coingecko,
  education,
  finance,
  health,
  planner,
  simulations,
  twitter,
  envelope,
  whatsapp,
  megaphone,
  menuFill,
} from "../../imports/icons";
import { profileIcons } from "../../imports/profile";

import "./styles.css";
import { languages } from "../../language/navbar.js";

function Navigation() {
  const { activeLanguage, handleLanguageChange } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingNotifyId, setLoadingNotifyId] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [copied, setCopied] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notifyList, setNotifyList] = useState([]);

  const copyToClipboard = () => {
    const text = document.querySelector(".truncate").textContent;

    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleNotificationClick = () => {
    setNotify(true);
  };

  async function handleChangeViewed(notifyId) {
    setLoadingNotifyId(notifyId);
    setIsLoading(true);
    try {
      await markNotificationAsRead(user.client_code, notifyId);
      await handleGetNotify();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleOffcanvasClose = () => {
    setNotify(false);
  };

  const changeMoment = (idiom) => {
    moment.locale(idiom || "en");
  };

  const formatTimeDifference = (date) => {
    const timestamp = moment(date.seconds * 1000);
    return timestamp.fromNow();
  };

  async function handleGetNotify() {
    if (user) {
      try {
        const notifications = await getNotifications(user.client_code);

        const sortedNotifications = notifications.sort(
          (a, b) => b.notify_date - a.notify_date
        );
        setNotifyList(sortedNotifications);

        const unreadNotifications = sortedNotifications.filter(
          (notification) => !notification.notify_viewed
        );
        setUnreadCount(unreadNotifications.length);
      } catch (error) {
        console.error("Error loading notifications:", error);
      }
    }
  }

  async function handleInitialData() {
    await handleGetNotify();
  }

  useEffect(() => {
    handleInitialData();
  }, []); //eslint-disable-line

  return (
    <nav>
      <div
        className="triangle"
        onClick={() => (user ? navigate(routes.dashboard.root) : navigate(routes.home))}
      >
        <img loading="lazy" alt="brand" src={triangleFill} />
      </div>
      <div></div>
      <div className="right-nav-on">
        {user && (
          <>
            <div className="btn-nav bell">
              <span className="rounded-circle">{unreadCount}</span>
              <div
                className="notify"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling"
                aria-controls="offcanvasScrolling"
                onClick={() => {
                  handleNotificationClick();
                  handleGetNotify();
                }}
              >
                <img loading="lazy" alt="notify" src={bell} />
              </div>
            </div>
            <div className="btn-nav exit" onClick={() => signOut()}>
              <img loading="lazy" alt="exit" src={out} />
            </div>
            <div
              className="btn-nav menu-inside"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            >
              <img loading="lazy" alt="profile" src={menuFill} />
            </div>
            <div
              className="client"
              onClick={() => navigate(routes.dashboard.profile)}
            >
              <img
                loading="lazy"
                alt="profile"
                src={profileIcons[user.client_img || 36]}
              />
            </div>
          </>
        )}
        {!user && (
          <div className="right-nav-off">
            <div className="account" onClick={() => navigate(routes.login)}>
              {languages[activeLanguage].membersArea}
            </div>
            <div className="divider">|</div>
            <div
              className="menu"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            >
              <img loading="lazy" alt="profile" src={menu} />
            </div>
          </div>
        )}
      </div>
      <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabIndex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
            <div className="title-brand">
              <img loading="lazy" alt="brand club" src={triangleFill} /> Thryad
              Club
            </div>
          </h5>
          {/* language */}
          <div className="offc-h-r">
            <div className="language">
              <div
                className={`en ${
                  activeLanguage === "en" ? "language-active" : ""
                }`}
                onClick={() => {
                  handleLanguageChange("en");
                  changeMoment("en");
                }}
              >
                EN
              </div>
              <div
                className={`fr ${
                  activeLanguage === "fr" ? "language-active" : ""
                }`}
                onClick={() => {
                  handleLanguageChange("fr");
                  changeMoment("fr");
                }}
              >
                FR
              </div>
              <div
                className={`pt ${
                  activeLanguage === "pt" ? "language-active" : ""
                }`}
                onClick={() => {
                  handleLanguageChange("pt");
                  changeMoment("pt");
                }}
              >
                PT
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleOffcanvasClose}
              ></button>
            </div>
          </div>
        </div>
        <div className="offcanvas-body">
          {notify ? (
            <div>
              {Array.isArray(notifyList) && notifyList.length > 0 ? (
                notifyList.map((notification) => (
                  <div
                    key={notification.notify_id}
                    className={`notify ${
                      !notification.notify_viewed ? "notify-on" : ""
                    }`}
                    onClick={
                      !notification.notify_viewed
                        ? () => handleChangeViewed(notification.notify_id)
                        : undefined
                    }
                  >
                    <h6
                      className={`${
                        !notification.notify_viewed ? "activated" : ""
                      }`}
                    >
                      {isLoading &&
                      loadingNotifyId === notification.notify_id ? (
                        <div
                          className="spinner-notify spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      ) : (
                        !notification.notify_viewed && (
                          <div className="dot"></div>
                        )
                      )}

                      {notification.notify_content[activeLanguage]?.title ||
                        notification.notify_content.en.title}
                    </h6>
                    <p>
                      {notification.notify_content[activeLanguage]?.message ||
                        notification.notify_content.en.message}
                    </p>
                    <div className="time-notify">
                      <small>
                        {formatTimeDifference(notification.notify_date)}
                      </small>
                    </div>
                    {notification.notify_link && (
                      <div
                        className="btn-notify"
                        onClick={() => navigate(`${notification.notify_link}`)}
                      >
                        {notification.notify_link_text}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>No notifications available.</div>
              )}
            </div>
          ) : (
            <div className="accordion accordion-flush" id="accordionFlushMenu">
              {/* collapseOne */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    {languages[activeLanguage].tokenomics.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    <div className="accordion-list-date coins-ct">
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.symbol}
                        </strong>{" "}
                        YAD
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.decimals}
                        </strong>{" "}
                        18
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.name}
                        </strong>{" "}
                        Thryad
                      </p>
                      <p>
                        <strong>{languages[activeLanguage].maxSupply}</strong>{" "}
                        {languages[activeLanguage].tokenomics.tenBillionTokens}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.sdg2}
                        </strong>{" "}
                        {languages[activeLanguage].tokenomics.oneBillionTokens}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.projectFounder}
                        </strong>{" "}
                        {languages[activeLanguage].tokenomics.oneBillionTokens}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.airdrop}
                        </strong>{" "}
                        {languages[activeLanguage].tokenomics.oneBillionTokens}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.renouncement}
                        </strong>{" "}
                        {languages[activeLanguage].tokenomics.comeSoon}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].tokenomics.network}
                        </strong>{" "}
                        Binance Smart Chain (BEP-20)
                      </p>
                      <div className="contract">
                        <p>
                          <strong>
                            {languages[activeLanguage].tokenomics.contract}
                          </strong>
                        </p>
                        <p className="truncate">
                          0x0c35c6D7c7A21f3299285840710437C50f608B19
                        </p>
                      </div>
                      <p className="accordion-p-two">
                        {languages[activeLanguage].tokenomics.descTokenomics}
                      </p>
                    </div>
                    <div className="accordion-buttons">
                      <div className="dex">
                        <button type="button" className="btn btn-outline-dark">
                          <img loading="lazy" alt="notify" src={pancake} />
                        </button>
                        <button type="button" className="btn btn-outline-dark">
                          <img loading="lazy" alt="notify" src={bscscan} />
                        </button>
                        <button type="button" className="btn btn-outline-dark">
                          <img
                            loading="lazy"
                            alt="notify"
                            src={coinmarketcap}
                          />
                        </button>
                        <button type="button" className="btn btn-outline-dark">
                          <img loading="lazy" alt="notify" src={coingecko} />
                        </button>
                      </div>
                      <button
                        type="submit"
                        onClick={copyToClipboard}
                        className={`btn btn-outline-dark ${
                          copied ? "copied" : ""
                        }`}
                      >
                        {
                          languages[activeLanguage].tokenomics[
                            copied ? "contractCopy" : "copyContract"
                          ]
                        }
                      </button>
                      <button
                        type="submit"
                        onClick={() => navigate(routes.login)}
                        className="btn btn-outline-dark"
                      >
                        {languages[activeLanguage].tokenomics.accessWallet}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* collapseTwo */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    {languages[activeLanguage].privateEquity.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    <p className="accordion-p-one">
                      <strong>
                        {
                          languages[activeLanguage].privateEquity
                            .participationProfitPerUnit
                        }
                      </strong>
                      <br />
                      {
                        languages[activeLanguage].privateEquity
                          .profitDistribution
                      }
                      <br />
                      <br />
                      <strong>
                        {
                          languages[activeLanguage].privateEquity
                            .allocationOfTotalRevenue
                        }
                      </strong>
                      <br />
                      {
                        languages[activeLanguage].privateEquity
                          .revenueAllocationDetails
                      }
                    </p>
                    <div className="accordion-list-date">
                      <p>
                        <strong>
                          {languages[activeLanguage].privateEquity.valuePerUnit}
                        </strong>{" "}
                        €10,00
                      </p>
                      <p>
                        <strong>{languages[activeLanguage].maxSupply}</strong>{" "}
                        4.500.000 {languages[activeLanguage].privateEquity.unit}
                      </p>
                      <p>
                        <strong>
                          {
                            languages[activeLanguage].privateEquity
                              .totalRevenueOfUnits
                          }
                        </strong>{" "}
                        €45.000.000
                      </p>
                      <p>
                        <strong>
                          {
                            languages[activeLanguage].privateEquity
                              .participationPerUnit
                          }
                        </strong>{" "}
                        0,0000222%
                      </p>
                      <p className="accordion-p-one">
                        <br />
                        {
                          languages[activeLanguage].privateEquity
                            .allocationOfTotalRevenue
                        }
                        :
                        <br />
                        <strong>
                          {
                            languages[activeLanguage].privateEquity
                              .services65EcoFriendly
                          }
                        </strong>{" "}
                        €29,250,000
                        <br />
                        <strong>
                          {languages[activeLanguage].privateEquity.liquidity25}
                        </strong>{" "}
                        €11,250,000
                        <br />
                        <strong>
                          {
                            languages[activeLanguage].privateEquity
                              .maintenance10
                          }
                        </strong>{" "}
                        €4,500,000
                        <br />
                        <br />
                        {
                          languages[activeLanguage].privateEquity
                            .divisionBetweenServices
                        }
                        <br />
                        <strong>
                          {languages[activeLanguage].privateEquity.energy40}
                        </strong>{" "}
                        €11,700,000
                        <br />
                        {
                          languages[activeLanguage].privateEquity
                            .focusOnRenewableEnergy
                        }
                        <br />
                        <br />
                        <strong>
                          {languages[activeLanguage].privateEquity.realEstate30}
                        </strong>{" "}
                        €8,775,000
                        <br />
                        {
                          languages[activeLanguage].privateEquity
                            .focusOnRealEstate
                        }
                        <br />
                        <br />
                        <strong>
                          {languages[activeLanguage].privateEquity.industry20}
                        </strong>{" "}
                        €5,850,000
                        <br />
                        {
                          languages[activeLanguage].privateEquity
                            .fundingForEcoFriendly
                        }
                        <br />
                        <br />
                        <strong>
                          {
                            languages[activeLanguage].privateEquity
                              .miningRawMaterials10
                          }
                        </strong>{" "}
                        €2,925,000
                        <br />
                        {
                          languages[activeLanguage].privateEquity
                            .focusOnRawMaterials
                        }
                      </p>
                    </div>
                    <div className="accordion-buttons">
                      {!user && (
                        <button
                          onClick={() => navigate(routes.login)}
                          type="submit"
                          className="btn btn-outline-dark"
                        >
                          {languages[activeLanguage].privateEquity.accessfunds}
                        </button>
                      )}
                      {user && (
                        <>
                          <button
                            type="submit"
                            className="btn btn-outline-dark"
                          >
                            {languages[activeLanguage].privateEquity.buyUnits}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-outline-dark"
                          >
                            {
                              languages[activeLanguage].privateEquity
                                .checkBalance
                            }
                          </button>
                          <button
                            type="submit"
                            className="btn btn-outline-dark"
                          >
                            {
                              languages[activeLanguage].privateEquity
                                .transferAmount
                            }
                          </button>
                          <button
                            type="submit"
                            className="btn btn-outline-dark"
                          >
                            {
                              languages[activeLanguage].privateEquity
                                .accessToReports
                            }
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* collapseThree */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    {languages[activeLanguage].membership.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    <p className="accordion-p-one">
                      {languages[activeLanguage].membership.description}
                    </p>
                    <div className="accordion-list-date">
                      <p>
                        <strong>
                          {
                            languages[activeLanguage].membership.plans.freePlan
                              .name
                          }
                        </strong>{" "}
                        {
                          languages[activeLanguage].membership.plans.freePlan
                            .price
                        }
                        <br />
                        {
                          languages[activeLanguage].membership.plans.freePlan
                            .details
                        }
                      </p>
                      <p>
                        <strong>
                          {
                            languages[activeLanguage].membership.plans.toolsPlan
                              .name
                          }
                        </strong>{" "}
                        {
                          languages[activeLanguage].membership.plans.toolsPlan
                            .price
                        }
                        <br />
                        {
                          languages[activeLanguage].membership.plans.toolsPlan
                            .details
                        }
                      </p>
                      <p>
                        <strong>
                          {
                            languages[activeLanguage].membership.plans
                              .healthyPlan.name
                          }
                        </strong>{" "}
                        {
                          languages[activeLanguage].membership.plans.healthyPlan
                            .price
                        }
                        <br />
                        {
                          languages[activeLanguage].membership.plans.healthyPlan
                            .details
                        }
                      </p>
                      <p>
                        <strong>
                          {
                            languages[activeLanguage].membership.plans
                              .premiumPlan.name
                          }
                        </strong>{" "}
                        {
                          languages[activeLanguage].membership.plans.premiumPlan
                            .price
                        }
                        <br />
                        <strong>
                          {
                            languages[activeLanguage].membership.plans
                              .premiumPlan.joiningFee
                          }
                        </strong>{" "}
                        {
                          languages[activeLanguage].membership.plans.premiumPlan
                            .unit
                        }
                        <br />
                        {
                          languages[activeLanguage].membership.plans.premiumPlan
                            .details
                        }
                      </p>
                    </div>
                    <div className="accordion-buttons">
                      <button
                        type="submit"
                        onClick={() => navigate(routes.login)}
                        className="btn btn-outline-dark"
                      >
                        {languages[activeLanguage].membership.seePlansAndPrices}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* collapseFour */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    {languages[activeLanguage].strategicPlan.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    <p className="accordion-p-one">
                      {languages[activeLanguage].strategicPlan.description}
                    </p>
                    <div className="accordion-list-date">
                      <p>
                        <strong>
                          {languages[activeLanguage].strategicPlan.step1.step}
                        </strong>{" "}
                        {languages[activeLanguage].strategicPlan.step1.title}
                        <br />
                        {languages[activeLanguage].strategicPlan.step1.details}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].strategicPlan.step2.step}
                        </strong>{" "}
                        {languages[activeLanguage].strategicPlan.step2.title}
                        <br />
                        {languages[activeLanguage].strategicPlan.step2.details}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].strategicPlan.step3.step}
                        </strong>{" "}
                        {languages[activeLanguage].strategicPlan.step3.title}
                        <br />
                        {languages[activeLanguage].strategicPlan.step3.details}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].strategicPlan.step4.step}
                        </strong>{" "}
                        {languages[activeLanguage].strategicPlan.step4.title}
                        <br />
                        {languages[activeLanguage].strategicPlan.step4.details}
                      </p>
                    </div>
                    <div className="accordion-buttons">
                      <button type="button" className="btn btn-outline-dark">
                        {languages[activeLanguage].strategicPlan.checkTimeline}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* collapseFive */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    {languages[activeLanguage].tools.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    {!user && (
                      <p className="accordion-p-one">
                        {languages[activeLanguage].tools.description}
                        <br />
                        <br />
                        <strong>
                          {languages[activeLanguage].tools.availableTools}
                        </strong>
                        <br />
                        <br />
                        <span className="center-tools">
                          <img loading="lazy" alt="notify" src={finance} />{" "}
                          {languages[activeLanguage].tools.finance}
                        </span>
                        <br />
                        <span className="center-tools">
                          <img loading="lazy" alt="notify" src={planner} />{" "}
                          {languages[activeLanguage].tools.planner}
                        </span>
                        <br />
                        <span className="center-tools">
                          <img loading="lazy" alt="notify" src={simulations} />{" "}
                          {languages[activeLanguage].tools.simulations}
                        </span>
                        <br />
                        <span className="center-tools">
                          <img loading="lazy" alt="notify" src={health} />{" "}
                          {languages[activeLanguage].tools.health}
                        </span>
                        <br />
                        <span className="center-tools">
                          <img loading="lazy" alt="notify" src={education} />{" "}
                          {languages[activeLanguage].tools.education}
                        </span>
                        <br />
                      </p>
                    )}
                    {user && (
                      <>
                        <p className="accordion-p-one">
                          {languages[activeLanguage].tools.prompt} <br />
                          {languages[activeLanguage].tools.options}
                        </p>
                        <div className="tools">
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                          >
                            <img loading="lazy" alt="notify" src={finance} />
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                          >
                            <img loading="lazy" alt="notify" src={planner} />
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                          >
                            <img
                              loading="lazy"
                              alt="notify"
                              src={simulations}
                            />
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                          >
                            <img loading="lazy" alt="notify" src={health} />
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                          >
                            <img loading="lazy" alt="notify" src={education} />
                          </button>
                        </div>
                      </>
                    )}
                    <div className="accordion-buttons">
                      {!user && (
                        <button
                          onClick={() => navigate(routes.login)}
                          type="submit"
                          className="btn btn-outline-dark"
                        >
                          {languages[activeLanguage].tools.accessTools}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* collapseSix */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    {languages[activeLanguage].club.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    <p className="accordion-p-one">
                      <strong>
                        {languages[activeLanguage].club.freedomAutonomy}
                      </strong>
                      <br />
                      {languages[activeLanguage].club.freedomAutonomyDetails}
                      <br />
                      <br />
                      <strong>
                        {languages[activeLanguage].club.digitalBlockchain}
                      </strong>
                      <br />
                      {languages[activeLanguage].club.digitalBlockchainDetails}
                      <br />
                      <br />
                      <strong>
                        {languages[activeLanguage].club.technologyInnovation}
                      </strong>
                      <br />
                      {
                        languages[activeLanguage].club
                          .technologyInnovationDetails
                      }
                    </p>
                  </div>
                </div>
              </div>
              {/* collapseSeven */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseSeven"
                  >
                    {languages[activeLanguage].contact.title}
                  </button>
                </h2>
                <div
                  id="flush-collapseSeven"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushMenu"
                >
                  <div className="accordion-body">
                    <p className="accordion-p-one">
                      {languages[activeLanguage].contact.description}
                    </p>
                    <div className="accordion-list-date">
                      <p>
                        <strong>
                          {languages[activeLanguage].contact.whatsapp}
                        </strong>{" "}
                        {languages[activeLanguage].contact.whatsappContent}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].contact.announcements}
                        </strong>{" "}
                        {languages[activeLanguage].contact.annoncesContent}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].contact.followUs}
                        </strong>{" "}
                        {languages[activeLanguage].contact.followUsContent}
                      </p>
                      <p>
                        <strong>
                          {languages[activeLanguage].contact.supportEmail}
                        </strong>{" "}
                        {languages[activeLanguage].contact.supportContent}
                      </p>
                    </div>
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() =>
                          window.open("https://wa.me/5548991586445", "_blank")
                        }
                      >
                        <img loading="lazy" alt="notify" src={whatsapp} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() =>
                          window.open("https://x.com/thryadclub", "_blank")
                        }
                      >
                        <img loading="lazy" alt="notify" src={twitter} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() =>
                          window.open("mailto:contact@thryad.club", "_blank")
                        }
                      >
                        <img loading="lazy" alt="notify" src={envelope} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() =>
                          window.open(
                            "https://www.thryad.club/annonces",
                            "_blank"
                          )
                        }
                      >
                        <img loading="lazy" alt="notify" src={megaphone} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
