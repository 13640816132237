const routes = {
    home: "/",
    login: "/login",
    password: "/password",
    game: "/game",
    register: "/register",
    dashboard: {
      root: "/dashboard",
      profile: "/dashboard/profile",
    },
  };
  
  export default routes;