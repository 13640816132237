export const languages = {
    en: {
      nickname: "Nickname",
      clientId: "Identifier",
      account: "Account",
      status: "Status",
      title: "Title",
      plans: {
        freePlan: "Free Plan",
        toolsPlan: "Tools Plan",
        healthyPlan: "Health Plan",
        premiumPlan: "Premium Plan",
      },
    },
    fr: {
      nickname: "Surnom",
      clientId: "Identifiant",
      account: "Compte",
      status: "Statut",
      title: "Titre",
      plans: {
        freePlan: "Plan Gratuit",
        toolsPlan: "Plan Outils",
        healthyPlan: "Plan Santé",
        premiumPlan: "Plan Premium",
      },
    },
    pt: {
      nickname: "Apelido",
      clientId: "Identificador",
      account: "Conta",
      status: "Status",
      title: "Título",
      plans: {
        freePlan: "Plano Gratuito",
        toolsPlan: "Plano Ferramentas",
        healthyPlan: "Plano Saúde",
        premiumPlan: "Plano Premium",
      },
    },
  };
  