export const notVerified = {
  notify_content: {
    en: {
      title: "Pending Verification",
      message:
        "Dear customer, for your safety, please check your email in the profile section. This will ensure your continued access and the integrity of your contact with us.",
    },
    fr: {
      title: "Vérification en attente",
      message:
        "Cher client, pour votre sécurité, veuillez vérifier votre e-mail dans l'onglet de votre profil. Cela garantira votre accès continu et l'intégrité de votre contact avec nous.",
    },
    pt: {
      title: "Verificação Pendente",
      message:
        "Caro cliente, para sua segurança, por favor, verifique seu e-mail na aba do seu perfil. Isso garantirá seu acesso contínuo e a integridade do seu contato conosco.",
    },
  },
  linkText: "",
  link: null,
};

export const clientInactive = {
  notify_content: {
    en: {
      title: "Incomplete Profile",
      message:
        "Dear customer, your profile has not been fully completed. Please complete the requested information to continue using our services.",
    },
    fr: {
      title: "Profil incomplet",
      message:
        "Cher client, votre profil n'a pas été entièrement complété. Veuillez compléter les informations demandées pour continuer à utiliser nos services.",
    },
    pt: {
      title: "Perfil Incompleto",
      message:
        "Caro cliente, seu perfil ainda não foi completamente preenchido. Complete as informações solicitadas para continuar com a utilização dos nossos serviços.",
    },
  },
  linkText: "",
  link: null,
};

export const clientBlocked = {
  notify_content: {
    en: {
      title: "Account Locked",
      message:
        "Your account has been locked for security reasons. Please access your profile and follow the steps to unlock it.",
    },
    fr: {
      title: "Compte verrouillé",
      message:
        "Votre compte a été verrouillé pour des raisons de sécurité. Veuillez accéder à votre profil et suivre les étapes pour le débloquer.",
    },
    pt: {
      title: "Conta Bloqueada",
      message:
        "Sua conta foi bloqueada por questões de segurança. Acesse seu perfil e verifique os passos para desbloqueá-la.",
    },
  },
  linkText: "",
  link: null,
};

export const amlPending = {
  notify_content: {
    en: {
      title: "Pending AML Verification",
      message:
        "Your AML (Anti-Money Laundering) verification has not been completed. Please access your profile and complete the verification to continue.",
    },
    fr: {
      title: "Vérification AML en attente",
      message:
        "Votre vérification AML (Anti-Blanchiment d'Argent) n'a pas été complétée. Veuillez accéder à votre profil et finaliser la vérification pour continuer.",
    },
    pt: {
      title: "Verificação AML Pendente",
      message:
        "Sua verificação AML (Anti-Money Laundering) não foi concluída. Acesse seu perfil e finalize a verificação para continuar.",
    },
  },
  linkText: "",
  link: null,
};

export const kycPending = {
  notify_content: {
    en: {
      title: "Pending KYC Verification",
      message:
        "Your KYC (Know Your Customer) verification is pending. To ensure security and compliance, please complete the verification as soon as possible.",
    },
    fr: {
      title: "Vérification KYC en attente",
      message:
        "Votre vérification KYC (Connaître Votre Client) est en attente. Pour garantir la sécurité et la conformité, veuillez compléter la vérification dès que possible.",
    },
    pt: {
      title: "Verificação KYC Pendente",
      message:
        "Sua verificação KYC (Conheça Seu Cliente) está pendente. Para garantir a segurança e conformidade, complete a verificação o quanto antes.",
    },
  },
  linkText: "",
  link: null,
};
