export const languages = {
  en: {
    passwordLengthError: "The password must be more than 6 characters",
    invalidEmail: "Invalid email!",
    nameLengthError: "The name must have at least 3 characters",
    enterFullName: "Please enter your full name",
    accountCreated: "Account successfully created!",
    registrationError: "Error trying to register!",
    alreadyMember: "Already a Member?",
    welcome: "WELCOME TO",
    referredBySomeone: "Were you referred by someone?",
    fullName: "Full Name",
    emailAddress: "Email Address",
    yourPassword: "Your password",
    agreeTerms: "I agree to the Terms of Service",
    agreePrivacy: "I agree to the Privacy Policy",
    access: "Access account",
    pleaseWait: "Please wait...",
    createAccount: "Create my account",
    termsImportant: "It is important that you access our",
    termsService: "Terms of Service",
    termsOur: "and our",
    termsPrivacy: "Privacy Policy.",
  },
  fr: {
    passwordLengthError: "Le mot de passe doit contenir plus de 6 caractères",
    invalidEmail: "E-mail invalide !",
    nameLengthError: "Le nom doit comporter au moins 3 caractères",
    enterFullName: "Veuillez entrer votre nom complet",
    accountCreated: "Compte créé avec succès !",
    registrationError: "Erreur lors de l'inscription !",
    alreadyMember: "Déjà membre ?",
    welcome: "BIENVENUE À",
    referredBySomeone: "Quelqu'un vous a recommandé ?",
    fullName: "Nom complet",
    emailAddress: "Adresse e-mail",
    yourPassword: "Votre mot de passe",
    agreeTerms: "J'accepte les Conditions d'utilisation",
    agreePrivacy: "J'accepte la Politique de confidentialité",
    access: "Accéder au compte",
    pleaseWait: "Veuillez patienter...",
    createAccount: "Créer mon compte",
    termsImportant: "Il est important que vous consultiez nos",
    termsService: "Conditions d'utilisation",
    termsOur: "et notre",
    termsPrivacy: "Politique de confidentialité.",
  },
  pt: {
    passwordLengthError: "A senha deve ter mais de 6 dígitos",
    invalidEmail: "Email inválido!",
    nameLengthError: "O nome deve ter pelo menos 3 caracteres",
    enterFullName: "Por favor, insira seu nome completo",
    accountCreated: "Conta criada com sucesso!",
    registrationError: "Erro ao tentar se cadastrar!",
    alreadyMember: "Já é Membro?",
    welcome: "BEM-VINDO À",
    referredBySomeone: "Alguém indicou você?",
    fullName: "Nome Completo",
    emailAddress: "Endereço de E-mail",
    yourPassword: "Sua senha",
    agreeTerms: "Estou de acordo com os Termos de Serviço",
    agreePrivacy: "Concordo com as Política de Privacidade",
    access: "Acessar conta",
    pleaseWait: "Aguarde...",
    createAccount: "Criar minha conta",
    termsImportant: "É importante que você acesse nossos",
    termsService: "Termos de Serviço",
    termsOur: "e nossa",
    termsPrivacy: "Política de Privacidade.",
  },
};
