import React from "react";
import Navigation from "../../components/Navigation";

function Home() {
  return (
    <div>
      <Navigation />
      {/* <h1>Thryad Club</h1> */}
    </div>
  );
}

export default Home;
