import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/Access/Login";
import Password from "../pages/Access/Password";
import Register from "../pages/Access/Register";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Dashboard/sections/Profile";
import Game from "../pages/Game";

import { useAuth } from "../context/Auth";
import { useLoading } from "../context/Load"; 

const PrivateRoutes = () => {
  const { user, loading } = useAuth(); 
  const { showLoading, hideLoading } = useLoading();

  React.useEffect(() => {
    if (loading) {
      showLoading();
    } else {
      hideLoading();
    }
    return () => hideLoading();
  }, [loading, showLoading, hideLoading]);

  if (loading) {
    return null;
  }

  return user ? <Outlet /> : <Navigate to="/" />;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/password" element={<Password />} />
      <Route path="/game" element={<Game />} />
      <Route path="/register/:optionalParam?" element={<Register />} />
      <Route path="/dashboard" element={<PrivateRoutes />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
