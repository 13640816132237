import {
  firestore,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "../Connection";
import generateClientCode from "./GenerateClientCode";

const createClient = async (user) => {
  const clientCode = await generateClientCode();

  const clientAuth = {
    client_code: clientCode,
    client_user: user.email,
    client_img: user.imgId,
    client_name: user.name,
    client_nickname: `${user.name.slice(0, 3)}${clientCode.slice(-6)}`,
    client_password: user.password,
    client_access: {
      client_permission: user.permission,
      client_rule: user.rule,
      client_status: user.status,
      client_verified: false,
    },
    client_verification: {
      client_number: null,
      client_time: null,
    },
    client_updated: {
      client_user: null,
      client_password: null,
      client_2fa: null,
    },
    client_metadata: {
      client_creation_date: user.creationTime,
      client_language: user.language,
      client_ip: user.clientIp,
      client_last_location: user.location,
      client_last_login: null,
    },
    client_2fa: {
      client_method: "",
      client_status: false,
    },
  };

  const clientData = {
    address: {
      client_country: "",
      client_state: "",
      client_city: "",
      client_district: "",
      client_street: "",
      client_number: "",
      client_complement: "",
      client_postal_code: "",
    },
    client: {
      client_birth: "",
      client_id: "",
      client_name: user.name,
      client_nickname: "",
      client_img: user.imgId,
      client_gender: {
        client_gender_others: false,
        client_gender_woman: false,
        client_gender_man: false,
        client_gender_unidentified: false,
      },
    },
    client_indication: {
      client_associate_date: user.associateDate,
      client_associate: user.indication,
      client_affiliate: [],
    },
    agreement: {
      client_aml: {
        update_date: null,
        acceptance_date: null,
        aml_verification: false,
      },
      client_kyc: {
        update_date: null,
        acceptance_date: null,
        kyc_verification: false,
      },
      client_privacy: {
        update_date: null,
        acceptance_date: user.creationTime,
        client_terms: true,
      },
      client_service: {
        update_date: null,
        acceptance_date: user.creationTime,
        client_policy: true,
      },
    },
    contact: {
      client_email: user.email,
      client_phone: "",
      client_whatsapp: "",
      client_instagram: "",
      client_facebook: "",
      client_x: "",
    },
  };

  const clientNotify = {
    notifications: [],
  };

  await setDoc(doc(firestore, "Auth", user.email), clientAuth);
  await setDoc(doc(firestore, "User", clientCode), clientData);
  await setDoc(doc(firestore, "Notify", clientCode), clientNotify);

  if (user.indication) {
    const indicationRef = doc(firestore, "User", user.indication);
    const indicationDoc = await getDoc(indicationRef);

    if (indicationDoc.exists()) {
      await updateDoc(indicationRef, {
        "client_indication.client_affiliate": arrayUnion({
          member_affiliate: clientCode,
          member_status: user.status,
          member_permission: user.permission,
          member_creation_date: user.creationTime,
        }),
      });
    }
  }

  return clientCode;
};

export default createClient;
