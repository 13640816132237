import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [activeLanguage, setActiveLanguage] = useState(() => {
    return localStorage.getItem("activeLanguage") || "en";
  });

  useEffect(() => {
    localStorage.setItem("activeLanguage", activeLanguage);
  }, [activeLanguage]);

  const handleLanguageChange = (language) => {
    setActiveLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ activeLanguage, handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
};
