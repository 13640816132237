import React, { useEffect, useContext } from "react";
import { useAuth } from "../../../../context/Auth";
import Navigation from "../../../../components/Navigation";
import Container from "../../../../components/Container";
import { verified } from "../../../../imports/icons";
import { LanguageContext } from "../../../../context/Idiom.js";

import "./styles.css";
import "../../../../spaces.css";
import { languages } from "../../../../language/profile.js";
import { pencil } from "../../../../imports/icons";

function Profile() {
  const { user } = useAuth();
  const { activeLanguage } = useContext(LanguageContext);

  const getPlanName = () => {
    switch (user.client_access.client_permission) {
      case "free":
        return languages[activeLanguage].plans.freePlan;
      case "tools":
        return languages[activeLanguage].plans.toolsPlan;
      case "healthy":
        return languages[activeLanguage].plans.healthyPlan;
      case "premium":
        return languages[activeLanguage].plans.premiumPlan;
      default:
        return null;
    }
  };

  useEffect(() => {
    // handleInitialData();
  }, []); //eslint-disable-line

  return (
    <div>
      <Navigation />
      <Container>
        <div>
          <div className="ct-info">
            <div className="profile-info">
              <div className="margin-right-28">
                <div className="title-label">
                  {languages[activeLanguage].nickname}
                </div>
                <div className="client-name">
                  <strong>
                    <h1>{user.client_nickname}</h1>
                  </strong>
                  <div className="verified">
                    <img loading="lazy" alt="eye" src={verified} />
                  </div>
                </div>
              </div>

              <div className="divider-profile margin-right-28"></div>

              <div className="margin-right-48">
                <div className="title-label">
                  {languages[activeLanguage].clientId}
                </div>
                <div>{user.client_code}</div>
              </div>

              <div className="margin-right-48 hide-mobile">
                <div className="title-label">
                  {languages[activeLanguage].account}
                </div>
                <div>{getPlanName()}</div>
              </div>

              <div className="margin-right-48 hide-mobile">
                <div className="title-label">
                  {languages[activeLanguage].status}
                </div>
                <div>{user.client_access.client_status}</div>
              </div>

              <div className="hide-mobile">
                <div className="title-label">
                  {languages[activeLanguage].title}
                </div>
                <div>{user.client_access.client_rule}</div>
              </div>
            </div>
            <div className="edit-profile" type="button">
              <img loading="lazy" alt="profile" src={pencil} />
            </div>
          </div>
          <div className="profile-datail">
            <div>
              <div className="title-label">
                {languages[activeLanguage].account}
              </div>
              <div>{getPlanName()}</div>
            </div>

            <div>
              <div className="title-label">
                {languages[activeLanguage].status}
              </div>
              <div>{user.client_access.client_status}</div>
            </div>

            <div>
              <div className="title-label">
                {languages[activeLanguage].title}
              </div>
              <div>{user.client_access.client_rule}</div>
            </div>
          </div>

          <div className="box-profile">
            <div className="value-profile"></div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Profile;
