export const languages = {
  en: {
    membersArea: "Members Area",
    accessMyAccount: "Access my account",
    maxSupply: "Max Supply:",

    tokenomics: {
      title: "Tokenomics",
      symbol: "Symbol:",
      decimals: "Decimals:",
      name: "Name:",
      sdg2: "(SDG) 2 - UN:",
      projectFounder: "Project founder:",
      airdrop: "Airdrop:",
      renouncement: "Renouncement:",
      network: "Network:",
      contract: "Contract:",
      descTokenomics:
        "A powerful, deflationary, and decentralized token designed for payments and investment opportunities.",
      comeSoon: "Come soon",
      oneBillionTokens: "1 billion tokens",
      tenBillionTokens: "10 billion tokens",
      copyContract: "Copy contract",
      contractCopy: "Contract copied",
      accessWallet: "Access wallet",
    },

    privateEquity: {
      title: "Private equity",
      participationProfitPerUnit: "Profit Sharing and Distribution",
      profitDistribution:
        "0.0000222% of the profit is assigned to each unit acquired. 80% of the club will be allocated to members, while 20% will remain with the founder.",
      allocationOfTotalRevenue: "Allocation of Total Revenue",
      revenueAllocationDetails:
        "65% of the funds will be invested in initiatives (Energy, Real Estate, Industry, and Raw Materials) to generate profits. 25% will be allocated to liquidity, and 10% to maintenance, including security, technology and marketing.",
      valuePerUnit: "Value per unit:",
      totalRevenueOfUnits: "Total revenue of units:",
      participationPerUnit: "Participation per unit:",
      profitPerUnit: "Profit per unit:",
      unit: "units",
      services65EcoFriendly: "Services 65% (eco-friendly):",
      liquidity25: "Liquidity (25%):",
      maintenance10: "Maintenance (10%):",
      divisionBetweenServices: "Division between services:",
      energy40: "Energy (40%):",
      realEstate30: "Real Estate (30%):",
      industry20: "Industry (20%):",
      miningRawMaterials10: "Raw Materials (10%):",
      focusOnRenewableEnergy:
        "- Focused on investments in solar and wind power plants, as well as exploring other renewable energy alternatives.",
      focusOnRealEstate:
        "- Investments will target residential constructions or acquisitions, corporate buildings, and shopping malls.",
      focusOnRawMaterials:
        "- Investments will support the extraction of essential raw materials, including sugarcane for biofuel production and hydrogen production.",
      fundingForEcoFriendly:
        "- Funding will be allocated to the production of eco-friendly products, such as biofuels and other green innovations.",
      buyUnits: "Buy units",
      checkBalance: "Check balance",
      transferAmount: "Transfer amount",
      accessToReports: "Access to reports",
      accessfunds: "Access funds",
    },

    membership: {
      title: "Membership",
      description:
        "As a valued member of our club, you gain unlimited access to a wealth of tools and resources, including financial management and the exclusive opportunity to participate in our Private Equity.",
      seePlansAndPrices: "See plans and prices",
      plans: {
        freePlan: {
          name: "Free Plan:",
          price: "€0,00 per month",
          details: "- Limited access to tools.",
        },
        toolsPlan: {
          name: "Tools Plan:",
          price: "€9,99 per month",
          details:
            "- Access to financial, management, and personal planning tools.",
        },
        healthyPlan: {
          name: "Healthy Plan:",
          price: "€12,99 per month",
          details:
            "- Access to the health area, natural treatment guides, articles, and health tools.",
        },
        premiumPlan: {
          name: "Premium Plan:",
          price: "€14,99 per month",
          unit: "10 units",
          joiningFee: "Joining fee:",
          details:
            "- Unlimited access to the Health, Tools, and Finance areas, and also gain access to Private Equity.",
        },
      },
    },

    strategicPlan: {
      title: "Strategic Plan",
      description:
        "Our strategic plan focuses on providing innovative and impactful solutions through key initiatives, designed to meet the diverse needs of businesses and individuals with flexibility and efficiency.",
      step1: {
        step: "Upcoming Action:",
        title: "Business Solutions",
        details:
          "- We offer services to help companies reduce their tax burdens by streamlining payments, bonuses, and benefits for partners, employees, and suppliers.",
      },
      step2: {
        step: "Upcoming Action:",
        title: "Store of Value",
        details:
          "- We will launch a centralized token backed by physical gold, designed to serve as a reliable store of value.",
      },
      step3: {
        step: "Upcoming Action:",
        title: "Prepaid Credit Card",
        details:
          "- Focused on convenience, our prepaid credit card will simplify financial management and make everyday use, travel, and expenses more practical.",
      },
      step4: {
        step: "Upcoming Action:",
        title: "Payment Solutions",
        details:
          "- We provide modern payment systems, including physical and digital terminals, tailored for businesses and commerce.",
      },
      checkTimeline: "Check the timeline",
    },

    tools: {
      title: "Our tools",
      description:
        "Access our tools now by logging into your account or creating one, and take control, organize, and grow efficiently and quickly.",
      availableTools: "The tools you will have access to:",
      finance: "Finance (Financial planning)",
      planner: "Planner (Personal organization)",
      simulations: "Simulations (Investments and interest)",
      health: "Health (Personal care)",
      education: "Education (Articles, books, and courses)",
      prompt: "What would you like to do?",
      options: "Finance | Planner | Simulations | Health | Education",
      accessTools: "Access tools",
    },

    club: {
      title: "The Club",
      freedomAutonomy: "Freedom and Autonomy",
      freedomAutonomyDetails:
        "Our club was created with the goal of offering financial freedom to all members, without state intervention. All transactions are conducted through blockchain, using the club's own currency, whether for plan payments or profit distribution. This approach guarantees complete financial autonomy and transparency in all operations, providing an experience free from the limitations of the traditional financial system.",
      digitalBlockchain: "Digital and Blockchain",
      digitalBlockchainDetails:
        "Our club has its own cryptocurrency, registered on the Binance Smart Chain, and also offers Private Equity, with shares being recorded directly on the blockchain, in the client's wallet. Each member owns a part of the club, and this participation is secure, transparent, and easily accessible through blockchain.",
      technologyInnovation: "Technology and Innovation",
      technologyInnovationDetails:
        "Our ecosystem is designed to offer the most modern and secure technologies available on the market. We utilize advanced technologies such as blockchain, tokens, NFTs, and Web3. These modern and innovative systems ensure not only security but also a smooth and efficient experience for our members.",
    },

    contact: {
      title: "Contact",
      description:
        "Our support channels are available in Portuguese (PT), French (FR), and English (EN). WhatsApp offers multilingual support as well as email to assist you in your preferred language.",
      whatsapp: "WhatsApp:",
      whatsappContent: "+55 48 99158-6445",
      followUs: "Follow us on:",
      followUsContent: "x.com/thryadclub",
      supportEmail: "Support email:",
      supportContent: "contact@thryad.club",
      whatsappLabel: "WhatsApp",
      emailLabel: "Email",
      announcements: "Announcements",
      annoncesContent: "thryad.club/annonces",
    },
  },
  fr: {
    membersArea: "Espace membre",
    accessMyAccount: "Accéder à mon compte",
    maxSupply: "Offre maximale:",

    tokenomics: {
      title: "À propos du token",
      symbol: "Symbole:",
      decimals: "Décimales:",
      name: "Nom:",
      sdg2: "(SDG) 2 - ONU:",
      projectFounder: "Fondateur du projet:",
      airdrop: "Airdrop:",
      renouncement: "Abandon:",
      network: "Réseau:",
      contract: "Contrat:",
      descTokenomics:
        "Un token puissant, déflationniste et décentralisé conçu pour les paiements et les opportunités d'investissement.",
      comeSoon: "À venir",
      oneBillionTokens: "1 milliard de tokens",
      tenBillionTokens: "10 milliards de tokens",
      copyContract: "Copier le contrat",
      contractCopy: "Contrat copié",
      accessWallet: "Accéder au portefeuille",
    },

    privateEquity: {
      title: "Capital-investissement",
      participationProfitPerUnit: "Participation et Répartition des Bénéfices",
      profitDistribution:
        "0,0000222 % du bénéfice est attribué à chaque unité acquise. 80 % du club seront alloués aux membres, tandis que 20 % resteront au fondateur.",
      allocationOfTotalRevenue: "Répartition des revenus totaux",
      revenueAllocationDetails:
        "65 % des fonds seront investis dans des initiatives (Énergie, Immobilier, Industrie et Matières premières) pour générer des profits. 25 % seront alloués à la liquidité, et 10 % à la maintenance, y compris la sécurité, la technologie et marketing.",
      valuePerUnit: "Valeur par unité :",
      totalRevenueOfUnits: "Revenu total des unités :",
      participationPerUnit: "Participation par unité :",
      profitPerUnit: "Profit par unité :",
      unit: "unités",
      services65EcoFriendly: "Services 65% (écologique) :",
      liquidity25: "Liquidité (25%) :",
      maintenance10: "Maintenance (10%) :",
      divisionBetweenServices: "Répartition entre les services :",
      energy40: "Énergie (40%) :",
      realEstate30: "Immobilier (30%) :",
      industry20: "Industrie (20%) :",
      miningRawMaterials10: "Matières premières (10%) :",
      focusOnRenewableEnergy:
        "- Axé sur les investissements dans des centrales solaires et éoliennes, ainsi que l'exploration d'autres alternatives d'énergie renouvelable.",
      focusOnRealEstate:
        "- Les investissements cibleront les constructions résidentielles ou les acquisitions, les bâtiments d'entreprise et les centres commerciaux.",
      focusOnRawMaterials:
        "- Les investissements soutiendront l'extraction de matières premières essentielles, y compris la canne à sucre pour la production de biocarburants et la production d'hydrogène.",
      fundingForEcoFriendly:
        "- Le financement sera alloué à la production de produits écologiques, tels que les biocarburants et d'autres innovations vertes.",
      buyUnits: "Acheter des unités",
      checkBalance: "Vérifier le solde",
      transferAmount: "Transférer le montant",
      accessToReports: "Accéder aux rapports",
      accessfunds: "Accéder aux fonds",
    },

    membership: {
      title: "Adhésion",
      description:
        "En tant que membre précieux de notre club, vous bénéficiez d'un accès illimité à une multitude d'outils et de ressources, y compris la gestion financière et l'opportunité exclusive de participer à notre Private Equity.",
      seePlansAndPrices: "Voir les plans et les prix",
      plans: {
        freePlan: {
          name: "Plan Gratuit:",
          price: "0,00 € par mois",
          details: "- Accès limité aux outils.",
        },
        toolsPlan: {
          name: "Plan Outils:",
          price: "9,99 € par mois",
          details:
            "- Accès aux outils de gestion financière, personnelle et de planification.",
        },
        healthyPlan: {
          name: "Plan Santé:",
          price: "14,99 € par mois",
          details:
            "- Accès à la zone santé, aux guides de traitements naturels, articles et outils de santé.",
        },
        premiumPlan: {
          name: "Plan Premium:",
          price: "14,99 € par mois",
          unit: "10 unités",
          joiningFee: "Cotisation d'adhésion:",
          details:
            "- Accès illimité aux zones Santé, Outils et Finance, et accès au Capital-investissement.",
        },
      },
    },

    strategicPlan: {
      title: "Plan stratégique",
      description:
        "Notre plan stratégique se concentre sur la fourniture de solutions innovantes et impactantes grâce à des initiatives clés, conçues pour répondre aux besoins diversifiés des entreprises et des particuliers avec flexibilité et efficacité.",
      step1: {
        step: "Action à Venir:",
        title: "Solutions commerciales",
        details:
          "- Nous proposons des services pour aider les entreprises à réduire leur charge fiscale en rationalisant les paiements, les primes et les avantages pour les partenaires, les employés et les fournisseurs.",
      },
      step2: {
        step: "Action à Venir:",
        title: "Réserve de valeur",
        details:
          "- Nous lancerons un token centralisé adossé à de l'or physique, conçu pour servir de réserve de valeur fiable.",
      },
      step3: {
        step: "Action à Venir:",
        title: "Carte de crédit prépayée",
        details:
          "- Axée sur la commodité, notre carte de crédit prépayée simplifiera la gestion financière et rendra l'utilisation quotidienne, les voyages et les dépenses plus pratiques.",
      },
      step4: {
        step: "Action à Venir:",
        title: "Solutions de paiement",
        details:
          "- Nous fournissons des systèmes de paiement modernes, y compris des terminaux physiques et numériques, adaptés aux entreprises et au commerce.",
      },
      checkTimeline: "Consultez la chronologie",
    },

    tools: {
      title: "Nos outils",
      description:
        "Accédez à nos outils dès maintenant en vous connectant à votre compte ou en en créant un, et prenez le contrôle, organisez et développez-vous efficacement et rapidement.",
      availableTools: "Les outils auxquels vous aurez accès :",
      finance: "Finance (Planification financière)",
      planner: "Planificateur (Organisation personnelle)",
      simulations: "Simulations (Investissements et intérêts)",
      health: "Santé (Soins personnels)",
      education: "Éducation (Articles, livres et cours)",
      prompt: "Que souhaitez-vous faire ?",
      options: "Finance | Planificateur | Simulations | Santé | Éducation",
      accessTools: "Accéder aux outils",
    },

    club: {
      title: "Le Club",
      freedomAutonomy: "Liberté et Autonomie",
      freedomAutonomyDetails:
        "Notre club a été créé dans le but d'offrir une liberté financière à tous les membres, sans intervention de l'État. Toutes les transactions sont effectuées via la blockchain, en utilisant la propre monnaie du club, que ce soit pour les paiements des plans ou la distribution des bénéfices. Cette approche garantit une autonomie financière complète et une transparence dans toutes les opérations, offrant une expérience libérée des limitations du système financier traditionnel.",
      digitalBlockchain: "Numérique et Blockchain",
      digitalBlockchainDetails:
        "Notre club possède sa propre cryptomonnaie, enregistrée sur la Binance Smart Chain, et propose également un Private Equity, avec des parts enregistrées directement sur la blockchain, dans le portefeuille du client. Chaque membre possède une part du club, et cette participation est sécurisée, transparente et facilement accessible via la blockchain.",
      technologyInnovation: "Technologie et Innovation",
      technologyInnovationDetails:
        "Notre écosystème est conçu pour offrir les technologies les plus modernes et sécurisées disponibles sur le marché. Nous utilisons des technologies avancées telles que blockchain, tokens, NFTs et Web3. Ces systèmes modernes et innovants garantissent non seulement la sécurité, mais également une expérience fluide et efficace pour nos membres.",
    },

    contact: {
      title: "Contact",
      description:
        "Nos canaux de support sont disponibles en portugais (PT), français (FR) et anglais (EN). WhatsApp propose une assistance multilingue ainsi qu'une assistance par e-mail pour vous aider dans la langue de votre choix.",
      whatsapp: "WhatsApp :",
      whatsappContent: "+55 48 99158-6445",
      followUs: "Suivez-nous sur :",
      followUsContent: "x.com/thryadclub",
      supportEmail: "Email de support :",
      supportContent: "contact@thryad.club",
      whatsappLabel: "WhatsApp",
      emailLabel: "Email",
      announcements: "Annonces",
      annoncesContent: "thryad.club/annonces",
    },
  },
  pt: {
    membersArea: "Área de Membros",
    accessMyAccount: "Acessar minha conta",
    maxSupply: "Oferta Máxima:",

    tokenomics: {
      title: "Sobre o Token",
      symbol: "Símbolo:",
      decimals: "Decimais:",
      name: "Nome:",
      sdg2: "(SDG) 2 - ONU:",
      projectFounder: "Fundador do projeto:",
      airdrop: "Airdrop:",
      renouncement: "Renúncia:",
      network: "Rede:",
      contract: "Contrato:",
      descTokenomics:
        "Um token poderoso, deflacionário e descentralizado, projetado para pagamentos e oportunidades de investimento.",
      comeSoon: "Em breve",
      oneBillionTokens: "1 bilhão de tokens",
      tenBillionTokens: "10 bilhões de tokens",
      copyContract: "Copiar contrato",
      contractCopy: "Contrato copiado",
      accessWallet: "Acessar carteira",
    },

    privateEquity: {
      title: "Capital Privado",
      participationProfitPerUnit: "Participação e Divisão do Lucro",
      profitDistribution:
        "0,0000222% do lucro é atribuído a cada unidade adquirida. 80% do clube será destinado aos membros, enquanto 20% permanecerão com o fundador.",
      allocationOfTotalRevenue: "Alocação Total da Receita",
      revenueAllocationDetails:
        "65% do valor será investido em iniciativas (Energia, Imóveis, Indústria e Matérias-primas) para gerar lucros. 25% serão destinados à liquidez, e 10% à manutenção, incluindo segurança, tecnologia e marketing.",
      valuePerUnit: "Valor por unidade:",
      totalRevenueOfUnits: "Receita total das unidades:",
      participationPerUnit: "Participação por unidade:",
      profitPerUnit: "Lucro por unidade:",
      unit: "unidades",
      services65EcoFriendly: "Serviços 65% (ecológicos):",
      liquidity25: "Liquidez (25%):",
      maintenance10: "Manutenção (10%):",
      divisionBetweenServices: "Divisão entre serviços:",
      energy40: "Energia (40%):",
      realEstate30: "Imóveis (30%):",
      industry20: "Indústria (20%):",
      miningRawMaterials10: "Matérias-primas (10%):",
      focusOnRenewableEnergy:
        "- Focado em investimentos em usinas de energia solar e eólica, além da exploração de outras alternativas de energia renovável.",
      focusOnRealEstate:
        "- Os investimentos visam construções residenciais ou aquisições, edifícios corporativos e shoppings.",
      focusOnRawMaterials:
        "- Os investimentos apoiarão a extração de matérias-primas essenciais, incluindo cana-de-açúcar para a produção de biocombustíveis e produção de hidrogênio.",
      fundingForEcoFriendly:
        "- O financiamento será alocado para a produção de produtos ecológicos, como biocombustíveis e outras inovações verdes.",
      buyUnits: "Comprar unidades",
      checkBalance: "Verificar saldo",
      transferAmount: "Transferir valor",
      accessToReports: "Acessar relatórios",
      accessfunds: "Acessar fundos",
    },

    membership: {
      title: "Assinatura",
      description:
        "Como um membro valioso do nosso clube, você tem acesso ilimitado a uma riqueza de ferramentas e recursos, incluindo gestão financeira e a oportunidade exclusiva de participar do nosso Private Equity.",
      seePlansAndPrices: "Vejar planos e preços",
      plans: {
        freePlan: {
          name: "Plano Gratuito:",
          price: "€0,00 por mês",
          details: "- Acesso limitado às ferramentas.",
        },
        toolsPlan: {
          name: "Plano Ferramentas:",
          price: "€9,99 por mês",
          details:
            "- Acesso às ferramentas de gestão financeira, pessoal e de planejamento.",
        },
        healthyPlan: {
          name: "Plano Saúde:",
          price: "€12,99 por mês",
          details:
            "- Acesso à área de saúde, guias de tratamentos naturais, artigos e ferramentas de saúde.",
        },
        premiumPlan: {
          name: "Plano Premium:",
          price: "€14,99 por mês",
          unit: "10 unidades",
          joiningFee: "Quota de adesão:",
          details:
            "- Acesso ilimitado às áreas de Saúde, Ferramentas e Finanças, além de acesso ao Capital Privado.",
        },
      },
    },

    strategicPlan: {
      title: "Plano Estratégico",
      description:
        "Nosso plano estratégico se concentra em fornecer soluções inovadoras e impactantes por meio de iniciativas importantes, projetadas para atender às diversas necessidades de empresas e indivíduos com flexibilidade e eficiência.",
      step1: {
        step: "Etapa Prevista:",
        title: "Soluções Empresariais",
        details:
          "- Oferecemos serviços para ajudar as empresas a reduzir sua carga tributária, otimizando pagamentos, bônus e benefícios para parceiros, funcionários e fornecedores.",
      },
      step2: {
        step: "Etapa Prevista:",
        title: "Reserva de Valor",
        details:
          "- Lançaremos um token centralizado lastreado em ouro físico, projetado para servir como uma reserva de valor confiável.",
      },
      step3: {
        step: "Etapa Prevista:",
        title: "Cartão de Crédito Pré-pago",
        details:
          "- Focado na conveniência, nosso cartão de crédito pré-pago simplificará a gestão financeira e tornará o uso cotidiano, viagens e despesas mais práticas.",
      },
      step4: {
        step: "Etapa Prevista:",
        title: "Soluções de Pagamento",
        details:
          "- Fornecemos sistemas de pagamento modernos, incluindo terminais físicos e digitais, adaptados para empresas e comércios.",
      },
      checkTimeline: "Ver linha do tempo ",
    },

    tools: {
      title: "Nossas ferramentas",
      description:
        "Acesse nossas ferramentas agora mesmo fazendo login na sua conta ou criando uma, e assuma o controle, organize-se e cresça de forma eficiente e rápida.",
      availableTools: "As ferramentas às quais você terá acesso:",
      finance: "Finanças (Planejamento financeiro)",
      planner: "Planejador (Organização pessoal)",
      simulations: "Simulações (Investimentos e juros)",
      health: "Saúde (Cuidados pessoais)",
      education: "Educação (Artigos, livros e cursos)",
      prompt: "O que você gostaria de fazer?",
      options: "Finanças | Planejador | Simulações | Saúde | Educação",
      accessTools: "Acessar ferramentas",
    },

    club: {
      title: "O Clube",
      freedomAutonomy: "Liberdade e Autonomia",
      freedomAutonomyDetails:
        "Nosso clube foi criado com o objetivo de oferecer liberdade financeira a todos os membros, sem intervenção do Estado. Todas as transações são realizadas por meio da blockchain, utilizando a própria moeda do clube, seja para pagamentos de planos ou distribuição de lucros. Essa abordagem garante total autonomia financeira e transparência em todas as operações, proporcionando uma experiência livre das limitações do sistema financeiro tradicional.",
      digitalBlockchain: "Digital e Blockchain",
      digitalBlockchainDetails:
        "Nosso clube possui sua própria criptomoeda, registrada na Binance Smart Chain, e também oferece Private Equity, com as cotas sendo registradas diretamente na blockchain, na carteira do cliente. Cada membro possui uma parte do clube, e essa participação é segura, transparente e de fácil acesso através da blockchain.",
      technologyInnovation: "Tecnologia e Inovação",
      technologyInnovationDetails:
        "Nosso ecossistema é projetado para oferecer as tecnologias mais modernas e seguras disponíveis no mercado. Utilizamos tecnologias avançadas como blockchain, tokens, NFTs e Web3. Esses sistemas modernos e inovadores garantem não apenas segurança, mas também uma experiência fluida e eficiente para nossos membros.",
    },

    contact: {
      title: "Contato",
      description:
        "Nossos canais de suporte estão disponíveis em português (PT), francês (FR) e inglês (EN). O WhatsApp oferece suporte multilíngue bem como e-mail para ajudá-lo no idioma de sua preferência.",
      whatsapp: "WhatsApp:",
      whatsappContent: "+55 48 99158-6445",
      followUs: "Siga-nos em:",
      followUsContent: "x.com/thryadclub",
      supportEmail: "E-mail de suporte:",
      supportContent: "contact@thryad.club",
      whatsappLabel: "WhatsApp",
      emailLabel: "E-mail",
      announcements: "Anúncios",
      annoncesContent: "thryad.club/annonces",
    },
  },
};
