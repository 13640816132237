export const languages = {
  en: {
    forgotPassword: "Forgot your password?",
    changePassword: "CHANGE PSSWD,",
    enterYourEmail: "Enter your Email",
    provideCode: "Provide the Code",
    createNewPassword: "Create Your New Password",
    pleaseWait: "Please wait...",
    verifyEmail: "Verify Email",
    verifyCode: "Verify Code",
    saveNewPassword: "Save New Password",
    contactSupport:
      "If you have issues changing your password, contact us via WhatsApp:",
    verificationEmailSent: "Verification email sent successfully!",
    emailSendError: "Error sending email. Please try again.",
    codeVerified: "Code verified successfully",
    invalidCode: "The code entered is incorrect or invalid!",
    codeExpired: "The verification code has expired. Please request a new one.",
    unexpectedError: "Unexpected error. Please try again.",
    emailNotFound: "Email not found in the system.",
    passwordUpdated: "Password updated successfully!",
    passwordUpdateError: "Error updating password",
  },
  fr: {
    forgotPassword: "Mot de passe oublié ?",
    changePassword: "CHANGER MDP,",
    enterYourEmail: "Entrez votre e-mail",
    provideCode: "Entrez le code",
    createNewPassword: "Créez votre nouveau mot de passe",
    pleaseWait: "Veuillez patienter...",
    verifyEmail: "Vérifier l'e-mail",
    verifyCode: "Vérifier le code",
    saveNewPassword: "Enregistrer le nouveau mot de passe",
    contactSupport:
      "En cas de problème pour changer votre mot de passe, contactez-nous via WhatsApp :",
    verificationEmailSent: "E-mail de vérification envoyé avec succès !",
    emailSendError: "Erreur lors de l'envoi de l'e-mail. Veuillez réessayer.",
    codeVerified: "Code vérifié avec succès",
    invalidCode: "Le code saisi est incorrect ou invalide !",
    codeExpired: "Le code de vérification a expiré. Demandez-en un nouveau.",
    unexpectedError:
      "The verification code has expired. Please request a new one.",
    emailNotFound: "E-mail introuvable dans le système.",
    passwordUpdated: "Mot de passe mis à jour avec succès !",
    passwordUpdateError: "Erreur lors de la mise à jour du mot de passe",
  },
  pt: {
    forgotPassword: "Esqueceu sua senha?",
    changePassword: "TROCAR SENHA,",
    enterYourEmail: "Digite seu E-mail",
    provideCode: "Informe o Código",
    createNewPassword: "Crie Sua Nova Senha",
    pleaseWait: "Aguarde...",
    verifyEmail: "Verificar e-mail",
    verifyCode: "Verificar código",
    saveNewPassword: "Salvar nova senha",
    contactSupport:
      "Caso tenha problema ao trocar a senha, fale conosco através do WhatsApp:",
    verificationEmailSent: "E-mail de verificação enviado com sucesso!",
    emailSendError: "Erro ao enviar e-mail. Tente novamente.",
    codeVerified: "Código verificado com sucesso",
    invalidCode: "Código informado está errado ou é inválido!",
    codeExpired: "O código de verificação expirou. Solicite um novo.",
    unexpectedError: "Erro inesperado. Tente novamente.",
    emailNotFound: " E-mail não encontrado no sistema.",
    passwordUpdated: "Senha atualizada com sucesso!",
    passwordUpdateError: "Erro ao atualizar senha",
  },
};
