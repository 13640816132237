import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ children }) => {
  const containerStyle = {
    padding: '28px',
  };

  return <div style={containerStyle}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
