import {
  firestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
} from "../Connection";

export const getNotifications = async (clientCode) => {
  try {
    const docRef = doc(firestore, "Notify", clientCode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const notifications = docSnap.data().notifications || [];
      if (notifications.length > 0) {
        return notifications;
      }
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return [];
  }
};

export const sendNotification = async (parameters, clientCode) => {
  const generateRandomId = () => Math.floor(100000 + Math.random() * 900000);

  const docRef = doc(firestore, "Notify", clientCode);
  const notifyDate = Timestamp.now();
  const docSnap = await getDoc(docRef);

  const existingNotifications = docSnap.data().notifications || [];

  const isIdUnique = (id) =>
    !existingNotifications.some(
      (notification) => notification.notify_id === id
    );

  const generateUniqueId = () => {
    let newId = generateRandomId();
    while (!isIdUnique(newId)) {
      newId = generateRandomId();
    }
    return newId;
  };

  const notifyId = generateUniqueId();

  const newNotification = {
    notify_id: notifyId,
    notify_date: notifyDate,
    notify_client: clientCode,
    notify_link_text: parameters.linkText || "",
    notify_link: parameters.link || null,
    notify_content: parameters.notify_content,
    notify_viewed: false,
  };

  await updateDoc(docRef, {
    notifications: arrayUnion(newNotification),
  });
};

export const markNotificationAsRead = async (clientCode, notifyId) => {
  try {
    const docRef = doc(firestore, "Notify", clientCode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const notifications = docSnap.data().notifications || [];
      const updatedNotifications = notifications.map((notification) =>
        notification.notify_id === notifyId
          ? { ...notification, notify_viewed: true }
          : notification
      );
      await setDoc(
        docRef,
        { notifications: updatedNotifications },
        { merge: true }
      );
    } else {
      console.error("Document not found in Firestore.");
    }
  } catch (error) {
    console.error("Error marking notification as read:", error);
  }
};
