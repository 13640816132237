import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, get, child } from "firebase/database";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAVtOwPOJOyjEDjcfmn0Hdl1G19n_QAVpw",
  authDomain: "thryad-club.firebaseapp.com",
  projectId: "thryad-club",
  storageBucket: "thryad-club.firebasestorage.app",
  messagingSenderId: "540162414853",
  appId: "1:540162414853:web:d5a305a1c7fdbef19aff6c",
  measurementId: "G-CGFD7HFN9L",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export {
  ref,
  set,
  get,
  child,
  database,
  analytics,
  firestore,
  auth,
  collection,
  arrayUnion,
  Timestamp,
  updateDoc,
  getDocs,
  getDoc,
  setDoc,
  query,
  where,
  doc,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
};
