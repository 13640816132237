import React from "react";
import Navigation from "../../components/Navigation";
import Container from "../../components/Container";


function Dashboard() {
  return (
    <div>
      <Navigation />
      <Container>
      <div><p>Dashboard</p></div>
      </Container>
    </div>
  );
}

export default Dashboard;
